<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neuer Download">
      <download-form
        :download="download"
        submit-label="Neuen Download anlegen"
        :form-submitted="saveDownload"
        mode="new"
      ></download-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DownloadForm from "@/components/forms/DownloadForm"
import DownloadsRepository from "@/repositories/downloads_repository.js"

export default {
  metaInfo: {
    title: "Neuer Download"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DownloadForm
  },
  data() {
    return {
      download: {
        description: ""
      }
    }
  },
  async created() {},
  methods: {
    async saveDownload(download) {
      await DownloadsRepository.create(download)
      this.$router.push({ name: "DownloadsPage" })
    }
  }
}
</script>
